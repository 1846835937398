import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import { GatsbyImage } from "gatsby-plugin-image"

const HomeIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={ location }>
      <Seo
        title="Snake River Devon Rex Home"
        keywords={[`devon rex`, `devon rex cats`, `cats`, `kittens`, `devon rex kittens`, `devon rex breeder`]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}
      <div className="home-image-container">
        <GatsbyImage 
          className="image"
          image={ data?.home1?.childImageSharp.gatsbyImageData } 
        />
        <GatsbyImage
          className="image"
          image={ data?.home2?.childImageSharp.gatsbyImageData } 
        />
      </div>
      <h3>Devon Rex Facts</h3>
      <div className="facts-container">
        <div className="fact">
          <h4>Personality</h4>
          <p>Adorable Devon Rex cats and kittens have characters like a cross between a cat, a dog, and a monkey. They are delightfully silly in both appearance and antics.</p>
        </div>
        <div className="fact">
          <h4>Environment</h4>
          <p>Keeping your Devon Rex cats and kittens indoors is key. Providing them acceptable surfaces, like scratching posts to suit their natural scratching behavior.</p>
        </div>
        <div className="fact">
          <h4>Appearance</h4>
          <p>The Devon Rex Cat has huge ears, a coat that can range from a wild curly to soft suede, with long skinny necks, and animated expressions.</p>
        </div>
        <div className="fact">
          <h4>Food</h4>
          <p>The Devon Rex Cat is a food hound - and it will never turn down a meal. For good health, Rex cats and kittens should not eat human food. Don't let their expression get the better of you - they act as if they have not had a meal in weeks.</p>
        </div>
      </div>
      <h3>Resources</h3>
      <p>Take the time to research the particular attributes of this special feline breed. A recommended source for superior cats and kittens information is the <a href='https://cfa.org/devon-rex' rel="noopener noreferrer" target='_blank'>Cat Fanciers Association</a>.</p>
      <h3>About Us</h3>
      <p>All of our cats and kittens come from either CFA or TICA or both associations. They are cute, curly, and outgoing. We are located near the joining of the Snake and Boise rivers in Idaho. Our home and cattery is in the country on 10 acres with cows, and of course our little monkeys (the Devons). It's like going to the zoo for free! You will never take a shower or eat alone again.</p>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    home1: file(name: { eq: "home1" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    home2: file(name: { eq: "home2" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

const App = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <HomeIndex location={props.location} props data={data} {...props} />
    )}
  />
)

export default App;